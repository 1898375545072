/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import {
  DataType, FieldType, FirestoreSchemaModel, doc, schemaUserFields
} from '@mindhiveoy/firebase-schema';
import { ShortUserRoleSet, UserRoleSet, userRole, userRoleContext } from '@mindhiveoy/auth';

import { EmailData } from './emailCenter';
import { EmailVerification } from './emailVerification';
import { Order, Service, TaxCreditSettings } from '.';
import { OrderRepeatingParameters } from './orders';
import { User } from './users/user';
import { VismaPayMerchant } from './vismaPay';

const roleContexes = {
  platform: userRoleContext({
    reference: 'users',
    permission: {
      full: ['system',],
      read: ['anyone',],
      update: ['anyone',],
      list: ['anyone',],
      write: ['anyone',],
    },
    level: 0,
    defaultRole: 'anyone',
  }),
};

// TODO: Merge ContextId and this UserRoleContextId. They are the same.
export type UserRoleContextId = keyof typeof roleContexes;

/**
 * Custom role's for the platform
 */
const roles = {
  /**
   * Normal user with account
   */
  user: userRole<UserRoleContextId>({
    name: 'User',
    context: 'platform',
  }),
  /**
   * Admin user
   */
  admin: userRole<UserRoleContextId>({
    name: 'Admin',
    context: 'platform',
  }),
};

export type UserRoleId = UserRoleSet<keyof typeof roles>;

// TODO autogenerate short user role id's
export type ShortUserRoleId = ShortUserRoleSet<'u' | 'a'>;

export const schema: FirestoreSchemaModel<UserRoleId, ShortUserRoleId> = {

  packages: [
    'auth',
    'firestore',
    'functions',
    // 'messaging',
    'storage',
    'performance',
  ],
  roles,
  roleContext: {
    contextPath: [],
    roleContexes,
  },

  collections: {
    users: {
      doc: doc<User<ShortUserRoleId>, UserRoleId>({
        paramId: 'uid',
        info: 'All Block users.',

        access: {
          functions: {
            isOwner: 'return request.auth != null && request.auth.uid == uid;',
            isAuthenticated: 'return request.auth != null',
          },
          permissions: {
            read: ['system', 'owner',],
            list: ['system',],
            create: ['isAuthenticated',],
            update: ['system', 'owner',],
          },
        },

        fields: {
          ...(schemaUserFields<'', '', UserRoleId>() as any),
          firstName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          lastName: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          streetAddress: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          postalCode: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          city: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          emailVerified: {
            dataType: DataType.Boolean,
            type: FieldType.Descriptor,
            description: 'Is email verified or not',
          },
        },
      }),
    },

    mailCenter: {
      doc: doc<EmailData, UserRoleId>({
        paramId: 'emailtoken',
        info: 'MailCenter to handle all emails, that leave from app.',
        fields: {
          to: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            required: true,
          },
          status: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          htmlPart: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          textPart: {
            dataType: DataType.DocumentId,
            type: FieldType.Descriptor,
            required: true,
          },
          fromUser: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
          },
          subject: {
            dataType: DataType.DateAndTime,
            type: FieldType.Descriptor,
          },
          error: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          attachments: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
      }),
    },

    emailVerifications: {
      doc: doc<EmailVerification, UserRoleId>({
        paramId: 'uid',
        info: 'You have to verify your email before you can use the app. All email verifications are here.',
        fields: {
          name: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          email: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          spaceId: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          userId: {
            dataType: DataType.DocumentId,
            type: FieldType.Descriptor,
            required: true,
          },
          expires: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
          },
          created: {
            dataType: DataType.DateAndTime,
            type: FieldType.Descriptor,
          },
          emailId: {
            dataType: DataType.DocumentId,
            type: FieldType.Descriptor,
          },
          error: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          status: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
        },
        indexes: {
          emailVerificationsByStatusAndUserId: [{
            fieldName: 'status',
            order: 'asc',
          }, {
            fieldName: 'userId',
            order: 'asc',
          },],
        },
      }),
    },

    services: {
      doc: doc<Service, UserRoleId>({
        paramId: 'serviceId',
        info: 'Service is defined in this document',
        access: {
          permissions: {
            full: ['system',],
            read: ['anyone',],
            list: ['anyone',],
          },
        },
        fields: {
          name: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          type: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          description: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          sanityId: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          shortDescription: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          category: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          imageUrl: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          canBeReducedInTaxes: {
            dataType: DataType.Boolean,
            type: FieldType.Descriptor,
          },
          serviceIncludes: {
            dataType: DataType.Array,
            type: FieldType.Descriptor,
          },
          extraServices: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          pricingModel: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
          timeBudget: {
            dataType: DataType.Array,
            type: FieldType.Descriptor,
          },
          id: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          subTypes: {
            dataType: DataType.Array,
            type: FieldType.Descriptor,
          },
          visible: {
            dataType: DataType.Boolean,
            type: FieldType.Descriptor,
          },
          serviceProvider: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
      }),
    },

    paymentInfo: {
      doc: doc<VismaPayMerchant, UserRoleId>({
        paramId: 'merchantId',
        info: 'Visma Pay merchant info',
        access: {
          permissions: {
            full: ['system',],
          },
        },
        fields: {
          serviceId: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Service unique id',
            required: true,
          },
          merchantId: {
            type: FieldType.Descriptor,
            dataType: DataType.Integer,
            description: 'Merchant unique id in Visma Pay submerhcnats list',
            required: true,
          },
          privateKey: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Merchant private key in Visma Pay',
            required: true,
          },
          apiKey: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Merchant API key in Visma Pay',
            required: true,
          },
          cp: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Provision level ID for the product',
            required: true,
          },
        },

      }),
    },

    orders: {
      doc: doc<Order, UserRoleId>({
        paramId: 'orderId',
        info: 'Single order by a customer',
        access: {
          permissions: {
            full: ['system',],
            read: ['anyone',],
          },
        },
        fields: {
          id: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Order unique id',
          },
          rows: {
            type: FieldType.Descriptor,
            dataType: DataType.Array,
            description: 'All order lines consisting some service that will be listed on receipt.',
          },
          totalPriceBeforeTaxes: {
            type: FieldType.Descriptor,
            dataType: DataType.Currency,
            required: true,
          },
          totalPrice: {
            type: FieldType.Descriptor,
            dataType: DataType.Currency,
            required: true,
          },
          totalVat: {
            type: FieldType.Descriptor,
            dataType: DataType.Currency,
            required: true,
          },
          creationDate: {
            type: FieldType.Descriptor,
            dataType: DataType.DateAndTime,
            description: 'Order date',
          },
          customerInfo: {
            type: FieldType.Descriptor,
            dataType: DataType.Map,
            description: 'Customer information',
          },
          uid: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'User\'s unique id',
            indexed: true,
          },
          status: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            required: true,
            indexed: true,
          },
          paymentStyle: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'payment style like Visma Pay, cash, etc',
          },
          paymentStatus: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'payment status',
          },
          paymentId: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'payment id to identify in payment system',
          },
        },
        indexes: {
          ordersByStatusAndUid: [{
            fieldName: 'status',
            order: 'asc',
          }, {
            fieldName: 'uid',
            order: 'asc',
          },],
          ordersByUidAndStatus: [{
            fieldName: 'uid',
            order: 'asc',
          }, {
            fieldName: 'status',
            order: 'asc',
          },],
        },
      }),
    },

    orderRepeatingParams: {
      doc: doc<OrderRepeatingParameters, UserRoleId>({
        paramId: 'orderRepeatingId',
        info: 'Order repeating parameters',
        access: {
          permissions: {
            full: ['system',],
          },
        },
        fields: {
          orderId: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Source order unique id',
          },
          repeatingRule: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Repeating rule in crontab format',
          },
          paymentToken: {
            type: FieldType.Descriptor,
            dataType: DataType.Text,
            description: 'Payment token for payment system',
          },
          paymentCounter: {
            type: FieldType.Descriptor,
            dataType: DataType.Integer,
            description: 'Number of unpayed executions',
          },
        },
      }),
    },

    settings: {
      doc: doc<TaxCreditSettings, UserRoleId>({
        paramId: 'settingsId',
        info: 'Settings for the app that can change over time (e.g. tax credit for household expenses)',
        access: {
          permissions: {
            full: ['system',],
            read: ['anyone',],
          },
        },
        fields: {
          taxCreditPercent: {
            type: FieldType.Descriptor,
            dataType: DataType.Integer,
            required: true,
          },
          threshold: {
            type: FieldType.Descriptor,
            dataType: DataType.Currency,
            required: true,
          },
          maximumCredit: {
            type: FieldType.Descriptor,
            dataType: DataType.Currency,
            required: true,
          },
        },

      }),
    },
  },
};
