import sanityClient from 'utils/sanity/sanityClient';

export type SlugMap = {
  [slug: string]: string;
}

export const slugs: SlugMap = {};

export const fetchSlugs = async (refs: string[]) => {
  if (refs.length === 0) {
    return slugs;
  }
  const toFetch = refs.filter((r) => !slugs[r]);
  console.log('zxcv', toFetch);
  if (toFetch.length === 0) {
    return slugs;
  }
  const client = sanityClient;

  const docs = await client.fetch(
    '*[_id in $refs]',
    {
      refs: toFetch,
    }
  );

  console.log('poiu', docs);

  docs.forEach((doc: any) => {
    if (!doc.slug) {
      return;
    }
    slugs[doc._id] = doc.slug.current;
  });
  return slugs;
};

export const scanRefs = (data: any): string[] => {
  // console.log(JSON.stringify(data));
  const refs: string[] = [];
  scanForInternalLinkRefs(data, refs);
  return refs;
};

export const initializeSlugs = (initialSlugs: SlugMap) => {
  if (!initialSlugs) {
    return;
  }
  Object.entries(initialSlugs).forEach(([id, slug,]) => {
    slugs[id] = slug;
  });
};

const scanForInternalLinkRefs = (data: any, refs: string[]) => {
  if (!data) {
    return;
  }
  if (Array.isArray(data)) {
    data.forEach((element) => scanForInternalLinkRefs(element, refs));
    return;
  }

  if (typeof data === 'object') {
    const keys = Object.keys(data);

    keys.forEach((key) => {
      const value = data[key];
      if (key === '_type' && value === 'link') {
        const ref = data.internalLink?._ref;
        if (ref) {
          refs.push(ref);
        }
        return;
      }
      if (key === '_type' && value === 'internalLink') {
        const ref = data.reference?._ref;
        if (ref) {
          refs.push(ref);
        }
        return;
      }
      scanForInternalLinkRefs(value, refs);
    });
  }
};

export default slugs;
