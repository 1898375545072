import { schema, ShortUserRoleId } from '../@shared/schema/schema';
import config from '../config';
import { EmailVerificationDocumentReference, EmailVerificationsCollectionReference } from './emailVerifications';
import { EmailDataDocumentReference, MailCenterCollectionReference } from './mailCenter';
import { OrderRepeatingParametersDocumentReference, OrderRepeatingParamsCollectionReference } from './orderRepeatingParams';
import { OrderDocumentReference, OrdersCollectionReference } from './orders';
import { PaymentInfoCollectionReference, VismaPayMerchantDocumentReference } from './paymentInfo';
import { ServiceDocumentReference, ServicesCollectionReference } from './services';
import { SettingsCollectionReference, TaxCreditSettingsDocumentReference } from './settings';
import { UserDocumentReference, UsersCollectionReference } from './users';
import { bootstrapRoles } from '@mindhiveoy/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { browserLocalPersistence, connectAuthEmulator, getAuth, setPersistence } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

declare module 'firebase/firestore' {
  /**
   * Gets a `CollectionReference` instance that refers to the collection at
   * the specified absolute path.
   *
   * @param firestore - A reference to the root Firestore instance.
   * @param collectionName - A name of the root collection.
   * argument.
   * @throws If the final path has an even number of segments and does not point
   * to a collection.
   * @returns The `CollectionReference` instance.
   */

function collection(
  ref: Firestore,
  collectionName: 'users'
): UsersCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'mailCenter'
): MailCenterCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'emailVerifications'
): EmailVerificationsCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'services'
): ServicesCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'paymentInfo'
): PaymentInfoCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'orders'
): OrdersCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'orderRepeatingParams'
): OrderRepeatingParamsCollectionReference;
function collection(
  ref: Firestore,
  collectionName: 'settings'
): SettingsCollectionReference;

function doc(
  ref: UsersCollectionReference,
  uid: string
): UserDocumentReference;
function doc(
  ref: MailCenterCollectionReference,
  emailtoken: string
): EmailDataDocumentReference;
function doc(
  ref: EmailVerificationsCollectionReference,
  uid: string
): EmailVerificationDocumentReference;
function doc(
  ref: ServicesCollectionReference,
  serviceId: string
): ServiceDocumentReference;
function doc(
  ref: PaymentInfoCollectionReference,
  merchantId: string
): VismaPayMerchantDocumentReference;
function doc(
  ref: OrdersCollectionReference,
  orderId: string
): OrderDocumentReference;
function doc(
  ref: OrderRepeatingParamsCollectionReference,
  orderRepeatingId: string
): OrderRepeatingParametersDocumentReference;
function doc(
  ref: SettingsCollectionReference,
  settingsId: string
): TaxCreditSettingsDocumentReference;
}
export type ContextParams = PlatformContextParams;

export type PlatformContextParams = {
  contextId: 'platform';
  params: {
  }
}

export type ContextId = 'platform';

bootstrapRoles(schema.roles);

let app: FirebaseApp;

export const firebaseApp = () => {
  if (app) {
    return app;
  }

  const isDevelopmentMode = config.environment.target === 'development' && !config.environment.disableEmulator;

  try {
    app = initializeApp(config.firebase) as FirebaseApp;

    // TODO include proxy logic. This is just a temporal build to give more time to do the actual framework
    // app = proxyApp(firebase as firebase.app.App) as any;

    if (isDevelopmentMode && schema.packages) {

      const host = 'localhost';
      console.log(`Debugger host: ${host}`);

     connectAuthEmulator(getAuth(app), `http://${host}:9099`);
      console.info('Running firebase auth emulator on port 9099.');

     connectFunctionsEmulator(getFunctions(app), host, 5001);
      console.info('Running firebase functions emulator on port 5001.');

      connectFirestoreEmulator(getFirestore(app), host, 8080);
      console.info('Running firebase firestore emulator on port 8080.');

     connectStorageEmulator(getStorage(app), host, 9199);
      console.info('Running firebase storage emulator on port 8080.');

    }
    if (!isDevelopmentMode) {
      /*
        * Firebase cloud functions's version of auth do not have setPersistence function. So we need to check its
        * existence dynamically and also call it dynamically
        */
      const auth = getAuth(app);

      if (typeof window !== 'undefined' ) {
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
          console.debug('Session persistance enabled.')
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // TODO Sentry
        });
      }
    }
  } catch (err) {
    // we skip the "already exists" message which is
    // not an actual error when we're hot-reloading
    if (!/already exists/.test(err.message)) {
      console.error('Firebase initialization error', err.message);
    }
  }

  return app;
};

